/* About.css */
.about-container {
  padding: 20px;
}


.about-section h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.about-section p {
  font-size: 1.1rem;
  color: #333;
}

    .about-section {
  display: flex;
}

.left-side,
.right-side {
  width: 50%;
}

.left-side {
 /* background-color: #EAE0D5;*/
  padding: 20px;
}

.right-side {
 /* background-color: #226F54;*/
  padding: 20px;
}

    .right-side img {
         width: 80%;
    }
    
        .left-side img {
         width: 80%;
    }
    
/* Adjust styles for smaller screens */
@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
  }

  .left-side,
  .right-side {
    width: 100%;
  }
}