


/* Home.css */
.hero-container {
  position: relative;
  height: 500px; /* Adjust the height as needed */
  background-image: url('../images/hero.jpg'); /* Replace 'path_to_your_image.jpg' with the path to your hero image */
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text {
  
}

.hero-text h1 {
  font-size: 3rem;
  margin-bottom: 20px;
    color: #DF4B51;
}

.hero-text p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.hero-text button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #ff4500; /* Change the background color as needed */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-text button:hover {
  background-color: #ff6347; /* Change the hover background color as needed */
}
/* Home.css */
.home-container {
  padding: 20px;
}

.home-header {
  text-align: center;
}

.home-header h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
     color: #DF4B51;
}

.home-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.content-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}

.content-item img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.content-item h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.content-item p {
  font-size: 1.1rem;
  color: #555;
}




