.blog-post-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.blog-header {
  text-align: center;
  margin-bottom: 20px;
}

.blog-header h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.blog-author-date {
  font-size: 0.9em;
  color: #555;
}

.blog-header-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.blog-content {
  margin-top: 20px;
}

.blog-content h2 {
  font-size: 1.8em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.blog-content p {
  line-height: 1.6;
  margin-bottom: 10px;
}

.blog-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.blog-content ul li {
  margin-bottom: 10px;
}

.blog-content-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
