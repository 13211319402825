/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
/* styles.css */
body {
  font-family: Arial, sans-serif;
  background-color: #ECE8F0; /* Background color */
  color: #333; /* Text color */
}

h2, h3 {
  color: #377FCC; /* Headings color */
}


form {
  background-color: #FFFFFF; /* Form background color */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

form label {
  color: #333; /* Form label color */
}

form input[type="text"],
form input[type="email"],
form input[type="file"],
form textarea {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #95BAE3; /* Form input border color */
  border-radius: 5px;
}

form button {
  background-color: #DF4B51; /* Submit button background color */
  color: #FFFFFF; /* Submit button text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

form button:hover {
  background-color: #F0848D; /* Submit button hover background color */
}


/* careers.css */
.careers-container {
  background-color: #FFFFFF; /* Background color for the Careers page */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.careers-container h2, .careers-container h3 {
  color: #377FCC; /* Headings color */
}

.careers-container p {
  color: #333; /* Text color */
}

.careers-container form {
  background-color: #FFFFFF; /* Form background color */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.careers-container form label {
  color: #333; /* Form label color */
}

.careers-container form input[type="text"],
.careers-container form input[type="email"],
.careers-container form input[type="file"],
.careers-container form textarea {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #95BAE3; /* Form input border color */
  border-radius: 5px;
}

.careers-container form button {
  background-color: #DF4B51; /* Submit button background color */
  color: #FFFFFF; /* Submit button text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.careers-container form button:hover {
  background-color: #F0848D; /* Submit button hover background color */
}


/* platform.css */
.platform-container {
  background-color: #FFFFFF; /* Background color for the Platform page */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.platform-container h2, .platform-container h3 {
  color: #377FCC; /* Headings color */
}

.platform-container p {
  color: #333; /* Text color */
}


/* contactUs.css */
.contact-container {
  background-color: #FFFFFF; /* Background color for the Contact Us page */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-container h2 {
  color: #377FCC; /* Headings color */
}

.contact-container p {
  color: #333; /* Text color */
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #95BAE3; /* Form input border color */
  border-radius: 5px;
}

textarea {
  height: 100px;
}

button {
  background-color: #DF4B51; /* Submit button background color */
  color: #FFFFFF; /* Submit button text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #F0848D; /* Submit button hover background color */
}

/* contactUs.css */
.success-message {
  color: green; /* Success message color */
  margin-top: 10px;
}

/* footer.css */
.footer {
  min-height: 100vh;
  width: 100%;
background: #2C3539;
  position: relative;
  padding: 70px 0;
}

.footer-row {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}

.footer-row p {
  color: #D5E4F4; /* Lightest color in the palette */
}

.footer-col {
  width: 25%;
  padding: 0 15px;
}

.footer-container {
  max-width: 1170px;
  margin: auto;
}

.footer-container ul li {
  list-style: none;
}

.footer-col h4 {
  font-size: 18px;
  color: #D5E4F4; /* Lightest color in the palette */
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-col h4:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0px;
  background-color: #377FCC; /* Darkest color in the palette */
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-col ul li:not(last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #FFFFFF; /* White color for links */
  text-decoration: none;
  font-weight: 300;
  color: #D5E4F4; /* Lightest color in the palette */
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #FFFFFF; /* White color on hover */
  padding-left: 8px;
}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: #377FCC; /* Darkest color in the palette */
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #FFFFFF; /* White color for social icons */
  transition: all 0.3s ease;
}

.footer-col .social-links a:hover {
  color: #F0848D; /* Accent color for social icons on hover */
  background-color: #DF4B51; /* Accent color for social icons on hover */
}

.footer-col dl dt:not(last-child) {
  margin-bottom: 10px;
  color: #FFFFFF; /* White color */
}

.footer-col dl dt {
  font-size: 16px;
  text-transform: capitalize;
  color: #FFFFFF; /* White color */
  text-decoration: none;
  font-weight: 300;
  color: #D5E4F4; /* Lightest color in the palette */
  display: block;
  transition: all 0.3s ease;
}

.footer-col dl dt:hover {
  color: #FFFFFF; /* White color on hover */
  padding-left: 8px;
}

@media (max-width: 767px) {
  .footer-row {
    flex-direction: column;
  }

  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 570px) {
  .footer-col {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .footer-col {
    width: 100%;
  }
}


.page-title-bar {
    background-color: #377FCC; /* Customize the color as needed */
    color: #FFFFFF; /* Customize the text color as needed */
    padding: 10px 20px;
    text-align: center;
    font-size: 1.5em;
}

.page-title-bar h3 {
     color: #FFFFFF;
}
