.blog-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.blog-header {
  text-align: center;
  margin-bottom: 20px;
}

.blog-header h1 {
  font-size: 2.5em;
}

.blog-content {
  line-height: 1.6;
}

.blog-content img {
  width: 100%;
  height: auto;
  margin: 20px 0;
}

.blog-content caption {
  display: block;
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

.content-section {
  margin-bottom: 30px;
}

.content-section h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

