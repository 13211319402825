.product-page {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.product-page h1 {
  font-size: 32px;
  margin-bottom: 20px;
     color: #DF4B51;
}

.product-page p {
  font-size: 18px;
  margin-bottom: 40px;
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: left;
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.product-info {
  padding: 20px;
}

.product-icon {
  font-size: 24px;
  color: #4a90e2;
  margin-bottom: 10px;
}

.product-info h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.product-info p {
  font-size: 16px;
  color: #666;
}


.virtual-learning-section {
  margin-top: 20px;
}

.virtual-learning-section h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

 .virtual-learning-section p {
  line-height: 1.6;
  margin-bottom: 10px;
}

.virtual-learning-section ul {
  list-style-type: disc;
  margin-left: 20px;
}

 .virtual-learning-section ul li {
  margin-bottom: 10px;
}

.virtual-learning-section h3 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}