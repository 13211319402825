/* insights.css */
.insights-container {
  background-color: #FFFFFF; /* Background color for the Insights page */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.insights-container h2, .insights-container h3 {
  color: #377FCC; /* Headings color */
}

.insights-container p {
  color: #333; /* Text color */
}

.insights-container a {
  color: #377FCC; /* Link color */
}

.insights-container a:hover {
  color: #5F99D6; /* Link hover color */
}

.blog-post {
  margin-bottom: 30px;
}

.blog-post h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.blog-post p {
  margin-bottom: 10px;
}

.read-more-link {
  color: #0073e6;
  text-decoration: none;
}

.read-more-link:hover {
  text-decoration: underline;
}