/* Basic Navbar styling */
.navbar {
    background-color: #FFFFFF; /* Customize as needed */
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: relative;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1300px;
    padding: 0 50px;
}

.navbar-logo {
    color: #FFFFFF;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
}

.logo-img {
    margin-right: 5px;
      width: 100%;
    height: 100%;
}

.menu-icon {
    display: none;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #FFFFFF;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-links:hover {
    background-color: #D5E4F4;
    border-radius: 4px;
}


/* navbar.css */
.navbar {
  background-color: #FFFFFF; /* Navbar background color #5f99D6 */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar ul li {
  display: inline;
  margin-right: 20px;
}

.navbar ul li a {
  color: #377FCC; /* Navbar link color */
  text-decoration: none;
}

.navbar ul li a:hover {
  text-decoration: underline;
}


/* Responsive styling */
@media screen and (max-width: 960px) {
    .navbar-container {
        padding: 0 20px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color:  #377FCC;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 160vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        background-color: #377FCC;
    }

    .nav-menu.active {
        background-color: #ECE8F0;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-item {
        width: 100%;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color:  #D5E4F4;
        border-radius: 0;
    }
    

}
