.industry-page {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.industry-section {
  margin-bottom: 40px;
  padding: 20px;
}

.industry-header-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.industry-section h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.industry-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.industry-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  flex: 1 1 calc(33% - 20px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.industry-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.industry-card p {
  font-size: 16px;
  line-height: 1.5;
}
