
/* services.css */
/*.services-container {
  background-color: #FFFFFF; 
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.services-container h2, .services-container h3 {
  color: #377FCC; 
}

.services-container p {
  color: #333; 
}

.service {
  margin-bottom: 20px;
}

.service h3 {
  margin-bottom: 5px;
}
*/
.services-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.service-section {
  margin-bottom: 40px;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.service-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.service-section img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px 0;
}

.service-section p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.service-section h3 {
  font-size: 20px;
  margin-top: 20px;
}

.service-section ul {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 18px;
  line-height: 1.6;
}

.service-section ul li {
  margin-bottom: 10px;
}
